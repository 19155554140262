<script lang="ts" setup>
import {Project, toggleProjectStar} from '/src/api/projects';
import Icon from '/src/components/layout/Icon.vue';
import router from '/src/router';

const props = defineProps<{
    project: Project;
    isAuthorized: boolean;
}>();

async function starProject() {
    if (!props.isAuthorized) {
        router.push('/login');
        return;
    }
    await toggleProjectStar(props.project.id);

    if (props.project) {
        props.project.is_starred = !props.project.is_starred;
        props.project.stars_count += props.project.is_starred ? 1 : -1;
    }
}
</script>
<template>
    <div
        class="tooltip tooltip-bottom capitalize"
        :data-tip="project.is_starred ? `Stars: ${project.stars_count ?? 0}` : `Add to favourites`"
    >
        <button
            v-if="project.is_starred"
            class="flex badge border-secondary w-8 h-8 px-0"
            @click.prevent="starProject"
        >
            <Icon
                v-if="project.is_starred"
                type="star-solid"
                primary
            />
        </button>
        <button
            v-else
            class="flex badge border-secondary w-8 h-8 px-0"
            @click.prevent="starProject"
        >
            <Icon :type="project.is_starred ? 'star-solid' : 'star'" />
        </button>
    </div>
</template>

<style></style>
