<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref, toRefs} from 'vue';
import {useSession} from '/src/core/session';
import GalleryProject from './GalleryProject.vue';
import {
    toggleProjectStar,
    getGalleryEditorsChoice,
    getGalleryStarred,
    getGalleryTrending,
    getPublicProjectsWithScreens,
} from '/src/api/projects';
import Icon from '/src/components/layout/Icon.vue';
import Button from '/src/components/layout/Button.vue';
import {useRoute} from 'vue-router';
import router from '/src/router';

const session = useSession();
const {state} = session;
const {auth} = toRefs(state);

const defaultCount = 30;

const projects = ref([]);
const activeTab = ref('highlights');
const isLoaded = ref(false);
const from = ref(0);
const to = ref(defaultCount);
const isNextVisible = ref(true);
const isNextDisabled = ref(false);
const platformSelected = ref();

const tabs = [
    {
        name: 'highlights',
        title: 'Highlights',
        description: 'Handpicked by Lopaka team',
        fn: getGalleryEditorsChoice,
    },
    {
        name: 'trending',
        title: 'Trending',
        description: 'Most starred by users',
        fn: getGalleryTrending,
    },
    {
        name: 'favourite',
        title: 'Favourites',
        description: 'Your stars',
        fn: getGalleryStarred,
        auth: true,
    },
    {
        name: 'all',
        title: 'Everything',
        description: 'All public projects',
        fn: getPublicProjectsWithScreens,
    },
];

async function nextPage() {
    from.value += defaultCount + 1;
    to.value += defaultCount + 1;
    isNextDisabled.value = true;
    await getProjects();
    isNextDisabled.value = false;
}

async function getProjects() {
    let result;

    const currentTab = tabs.find((t) => t.name === activeTab.value);
    if (currentTab?.fn) {
        result = (await currentTab.fn(from.value, to.value, platformSelected.value)) ?? [];
        projects.value.push(...result);
    }
    if (result.length < defaultCount) {
        isNextVisible.value = false;
    }
}

async function setActiveTab(tabname?) {
    from.value = 0;
    to.value = defaultCount;
    projects.value = [];
    isNextVisible.value = true;

    activeTab.value = tabname ? tabname : activeTab.value;
    isLoaded.value = false;
    await getProjects();
    isLoaded.value = true;
}

function resetPlatform() {
    platformSelected.value = undefined;
    setActiveTab();
}

onMounted(async () => {
    const route = useRoute();
    if (route.hash) {
        activeTab.value = route.hash.substring(1);
    }
    isLoaded.value = false;
    await getProjects();
    isLoaded.value = true;
});

const scrollContainer = ref(null);

function handleScroll() {
    if (!scrollContainer.value) return;

    const {scrollHeight, scrollTop, clientHeight} = scrollContainer.value;
    if (scrollHeight - scrollTop <= clientHeight && isNextVisible.value && !isNextDisabled.value) {
        nextPage();
    }
}

onMounted(() => {
    scrollContainer.value = document.documentElement;
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>
<template>
    <div class="my-4 text-center">
        <div class="text-xl font-bold">"Every screen is a painting"</div>
        <div class="text-lg">Public projects created by our users</div>
    </div>
    <div class="mb-6 flex flex-row items-center justify-between">
        <div class="flex flex-row gap-4 items-center">
            <div
                role="tablist"
                class="tabs tabs-boxed w-fit bg-secondary font-bold"
            >
                <a
                    v-for="tab in tabs"
                    :key="tab.name"
                    :href="`#${tab.name}`"
                    role="tab"
                    class="tab"
                    :class="{'tab-active': activeTab === tab.name, hidden: tab.auth == !auth}"
                    @click="setActiveTab(tab.name)"
                >
                    {{ tab.title }}
                </a>
            </div>
            <template
                v-for="tab in tabs"
                :key="tab.name"
            >
                <div v-if="activeTab === tab.name">{{ tab.description }}</div>
            </template>
        </div>
        <div class="form-control flex flex-row">
            <label class="label pr-3">
                <span class="label-text">Platform</span>
            </label>
            <select
                class="select select-bordered select-sm"
                v-model="platformSelected"
                @change="setActiveTab()"
            >
                <option
                    disabled
                    selected
                    value=""
                >
                    Show only...
                </option>
                <option value="tft-espi">tft-espi</option>
                <option value="u8g2">u8g2</option>
                <option value="adafruit_gfx">adafruit_gfx</option>
                <option value="adafruit_gfx_mono">adafruit_gfx_mono</option>
                <option value="inkplate">inkplate</option>
                <option value="flipper">flipper</option>
            </select>
            <div
                class="label pl-3 cursor-pointer"
                v-show="!!platformSelected"
                @click="resetPlatform"
            >
                <Icon
                    type="x"
                    sm
                />
            </div>
        </div>
    </div>
    <div
        v-if="!isLoaded"
        class="mx-auto py-48 text-center"
    >
        <span class="loading loading-spinner text-primary loading-lg"></span>
    </div>
    <template v-else>
        <div class="grid md:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
            <template
                v-for="project in projects"
                :key="project.id"
            >
                <div
                    v-if="project.screens && project.screens[0].img_preview"
                    class=""
                >
                    <GalleryProject
                        :project="project"
                        :backgroundColor="session.getPlatformFeatures(project.platform).screenBgColor"
                        :isAuthorized="!!auth"
                    />
                </div>
            </template>
        </div>
        <Button
            class="mx-auto mb-8"
            @click="nextPage"
            v-if="isNextVisible"
            :disabled="isNextDisabled"
        >
            <span
                v-show="isNextDisabled"
                class="loading loading-spinner"
            ></span>
            Show more
        </Button>
    </template>
</template>
<style lang="css"></style>
