<script lang="ts" setup>
import {useSession} from '../core/session';
import Navbar from './layout/Navbar.vue';

useSession();
</script>
<template>
    <Navbar></Navbar>
    <div class="flex flex-col flex-grow gap-4 md:hidden text-center text-xl py-20 px-4">
        <p>Graphics editor for u8g2, Adafruit_GFX, TFT_eSPI, FlipperZero, InkPlate</p>
        <ul class="text-sm">
            <li>Generates C source code</li>
            <li>Converts images to bitmaps</li>
            <li>Got hundreds of fonts</li>
        </ul>
        <p><b>This app works best on desktops</b></p>
        <a
            href="https://www.youtube.com/embed/kTxVWBAW4ig?si=IJx4kYZQFi4Opm0W"
            class="link link-primary"
        >
            Watch the quick start guide
        </a>
        <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/kTxVWBAW4ig?si=IJx4kYZQFi4Opm0W"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
        ></iframe>
    </div>
    <div class="flex flex-col flex-grow px-3">
        <router-view></router-view>
    </div>
</template>
<style lang="css">
body {
    visibility: visible !important;
}
</style>
