<script lang="ts" setup>
import {ref, toRefs, watch, onMounted, onUnmounted} from 'vue';
import {Point} from '../../core/point';
import {useSession} from '../../core/session';
import {debounce} from '/src/utils';

const session = useSession();
const {scale} = toRefs(session.state);
const selectedScale = ref(scale.value ? scale.value.x * 100 : 100);

watch(
    selectedScale,
    debounce((val) => session.setScale(val, true), 500)
);

const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey || event.ctrlKey) {
        if (event.key === '=') {
            event.preventDefault();
            selectedScale.value = Math.min(selectedScale.value + 50, 1000);
        }
        if (event.key === '-') {
            event.preventDefault();
            selectedScale.value = Math.max(selectedScale.value - 50, 50);
        }
    }
};

onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
});
</script>
<template>
    <div class="flex flex-row items-center mr-16">
        <label
            for="canvas-scale"
            class="text-sm pr-2 pb-1"
        >
            Zoom:
        </label>
        <div
            class="tooltip tooltip-right"
            :data-tip="`${selectedScale}%`"
        >
            <input
                class="range range-xs w-32"
                type="range"
                min="50"
                max="1000"
                step="50"
                v-model="selectedScale"
                id="canvas-scale"
                @dblclick="selectedScale = 100"
            />
        </div>
    </div>
</template>
<style lang="css"></style>
