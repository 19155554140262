<script lang="ts" setup>
import {onMounted} from 'vue';
import {useSession} from '/src/core/session';
import router from '/src/router';

const session = useSession();
const {state} = session;

// dummy page for "buy now" redirects after sign in
onMounted(() => {
    if (!state.auth) {
    }
});
</script>
<template></template>
<style lang="css"></style>
